module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Honeycomb Design System Styleguide","short_name":"Honeycomb","start_url":"/","background_color":"#fff","theme_color":"#97d700","display":"minimal-ui","icon":"src/images/favicon.png","theme_color_in_head":false,"legacy":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"30b283c85cafcad6737d6ab050915499"},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"className":"toc-anchor-icon","elements":["h2","h3","h4"],"icon":"<svg class=\"flix-svg-link\" aria-hidden=\"true\" xmlns=\"http://www.w3.org/2000/svg\" xmlns:xlink=\"http://www.w3.org/1999/xlink\" viewBox=\"0 0 1000 1000\"><path d=\"M277.3 916.7A156.2 156.2 0 0 1 166.2 870.8L129.2 833.8A157.3 157.3 0 0 1 129.2 611.6L330.4 410.5A157.2 157.2 0 0 1 552.5 410.5L571 429A20.8 20.8 0 1 1 541.8 458.1L523.3 439.7A116 116 0 0 0 441.5 406.8H441.5A116 116 0 0 0 359.7 439.7L158.3 640.8A115.8 115.8 0 0 0 158.3 804.6L195.3 841.5A118.5 118.5 0 0 0 359 841.5L459.5 740.9A20.8 20.8 0 0 1 488.7 740.9H488.7A20.8 20.8 0 0 1 488.7 770.1L388.3 870.8A156 156 0 0 1 277.3 916.7ZM558.3 635.6A156.4 156.4 0 0 1 447.2 589.8L428.8 571.2A20.8 20.8 0 0 1 458.3 541.7L476.8 560.2A116 116 0 0 0 558.6 593H558.6A116.1 116.1 0 0 0 640.5 560.2L841.6 359A115.5 115.5 0 0 0 841.6 195.3L804.7 158.4A116 116 0 0 0 722.9 125.5H722.9A115.9 115.9 0 0 0 641 158.4L540.2 259.1A20.8 20.8 0 0 1 511 259.1 20.8 20.8 0 0 1 511 230L611.5 129.2A155.9 155.9 0 0 1 722.6 83.3H722.6A156.1 156.1 0 0 1 833.7 129.2L870.6 166.1A157.1 157.1 0 0 1 870.6 388.3L669.5 589.4A156.3 156.3 0 0 1 558.3 635.6Z\"/></svg>","offsetY":0},
    },{
      plugin: require('../gatsby-browser.jsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
